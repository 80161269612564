@import url(https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Space+Mono:wght@700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --accent-color: #f87070;
  --background-body: #1e213f;
  --text: #d7e0ff;
  --text-dark: #1e213f;
  --background-preferences: #ffffff;
  --input-background: #eff1fa;
  --background-timer: #161932;
  --settings-heading: #161932;
  --default-accent: #f87070;
  --blue-accent: #70f3f8;
  --purple-accent: #d881f8;

  --font-current: "Kumbh Sans", sans-serif;
  --font-default: "Kumbh Sans", sans-serif;
  --font-roboto-slab: "Roboto Slab", serif;
  --font-space-mono: "Space Mono", monospace;
}

html,
body {
  height: 100%;
}

body {
  display: flex;
  justify-content: center;
  background: #1e213f;
  background: var(--background-body);
}

.pomodoro-app {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 667px;
  font-family: "Kumbh Sans", sans-serif;
  font-family: var(--font-current);
}

h1 {
  font-family: "Kumbh Sans", sans-serif;
  color: #d7e0ff;
  color: var(--text);
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  margin-top: 32px;
}

.controls {
  display: flex;
  justify-content: space-between;
  background: #161932;
  background: var(--background-timer);
  border-radius: 31.5px;
  width: 327px;
  min-height: 63px;
  padding: 8px 6px 8px 6px;
  margin: 45px 0 48px 0;
  z-index: 100;
}

/* hide radio inputs so the labels can be styled */
/* display: none; will make them unfocusable / no keyboard navigation */
.controls input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}

.controls__button {
  display: flex;
  align-items: center;
  height: 48px;
  border: none;
  border-radius: 26.5px;
  background: #161932;
  background: var(--background-timer);
  padding: 0 15px 0 15px;

  font-family: "Kumbh Sans", sans-serif;

  font-family: var(--font-current);
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  color: #d7e0ff;
  color: var(--text);
  mix-blend-mode: normal;
  opacity: 0.4;
  cursor: pointer;
}

.controls input[type="radio"]:checked + label {
  color: #1e213f;
  color: var(--text-dark);
  opacity: 1;
  background: #f87070;
  background: var(--accent-color);
  animation-name: fade;
  animation-timing-function: ease-in;
  animation-duration: 0.05s;
}

.timer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 300px;
  height: 300px;
  border-radius: 90%;
  background: linear-gradient(315deg, #2e325a 0%, #0e112a 100%);
  box-shadow: -50px -50px 100px #272c5a, 50px 50px 100px #121530;
  cursor: pointer;
}

.timer:active {
  transform: scale(0.98);
}

.timer__display {
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  /* flex-wrap: wrap; */
  height: 89.26%;
  width: 89.26%;
  padding: 11px;
  border-radius: 90%;
  background: #161932;
  background: var(--background-timer);
}

button.display__mute {
  width: 25px;
  height: 25px;
  border: 0;
  background-color: transparent;
  color: #d7e0ff2f;
  transition: color 0.3s ease-in-out;
  cursor: pointer;
}

button.display__mute:hover {
  color: #d7e0ff;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

.display__start-pause {
  margin-top: 50%;
  font-family: "Kumbh Sans", sans-serif;
  font-family: var(--font-default);
  font-weight: bold;
  font-size: 16px;
  background-color: #161932;
  background-color: var(--background-timer);
  border: 0;
  color: #d7e0ff;
  color: var(--text);
  line-height: 16px;
  letter-spacing: 15px;
  margin-right: -15px; /* Accounts for extra whitespace letter-spacing introduced */
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
}

.pomodoro-app__preferences {
  margin-top: 79px;
  background: none;
  border: 0;
  transition: transform 0.15s ease-in-out;
  cursor: pointer;
}

.pomodoro-app__preferences:hover {
  transform: scale(1.4) rotate(45deg);
}

.preferences {
  z-index: 200;
  position: fixed;
  display: none;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

@keyframes fade {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}

.preferences--visible {
  display: block;
  animation-name: fade;
  animation-timing-function: ease-in;
  animation-duration: 0.15s;
}

.preferences__pane {
  top: 46px;
  width: 327px;
  height: 549px;
  background: #ffffff;
  background: var(--background-preferences);
  border-radius: 15px;

  position: relative;
  margin: auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition: 3.25s all ease-in-out;
}

.preferences__pane h2 {
  padding: 24px 0 28px 24px;
  border-bottom: 1px solid #e3e1e1;
  font-family: "Kumbh Sans", sans-serif;
  font-family: var(--font-default);
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  color: #161932;
  color: var(--settings-heading);
}

.pane__close-preferences {
  border: none;
  background: none;
  position: absolute;
  right: 24px;
  top: 19px;
  color: #1e213f;
  color: var(--background-body);
  opacity: 0.5;
  font-size: 24px;
  transition: transform 0.15s ease-in-out;
}

.pane__close-preferences:hover {
  transform: scale(1.65);
  cursor: pointer;
}

h3 {
  width: 100%;
  text-align: center;
  font-family: "Kumbh Sans", sans-serif;
  font-family: var(--font-default);
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 11px;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 4.23077px;
  color: #161932;
  color: var(--settings-heading);
}

.pane__time-settings,
.pane__color-preference {
  margin-top: 28px;
}

.time-settings__form {
  margin: 18px 24px 24px 23px;
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
  grid-row-gap: 8px;
  row-gap: 8px;
}

label {
  font-family: "Kumbh Sans", sans-serif;
  font-family: var(--font-default);
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  color: #1e213f;
  color: var(--text-dark);
  mix-blend-mode: normal;
  opacity: 0.4;
}

input {
  width: 140px;
  height: 40px;
  padding-left: 16px;
  border: none;
  border-radius: 10px;
  background: #eff1fa;
  background: var(--input-background);
  font-family: var();
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  color: #1e213f;
  color: var(--text-dark);
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  margin-right: 16px;
  -webkit-appearance: none;
  background: url("data:image/svg+xml,%3Csvg width='14' height='21' viewBox='0 0 14 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 6L7 2L13 6' stroke='%231E213F' stroke-opacity='0.25' stroke-width='2'/%3E%3Cpath d='M1 15L7 19L13 15' stroke='%231E213F' stroke-opacity='0.25' stroke-width='2'/%3E%3C/svg%3E%0A")
    no-repeat center center;
  height: 21px;
  width: 14px;
  opacity: 0.5; /* shows Spin Buttons per default (Chrome >= 39) */
  cursor: pointer;
}

input[type="number"]::-webkit-inner-spin-button:hover,
input[type="number"]::-webkit-inner-spin-button:active {
  opacity: 1;
}

/* hide radio inputs so the labels can be styled */
/* display: none; will make them unfocusable / no keyboard navigation */
.pane__color-preference input[type="radio"],
.pane__font-preference input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}

.pane__color-preference input[type="radio"] + label,
.pane__font-preference input[type="radio"] + label {
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.25s ease-in-out, background-color 0.25s ease-in-out;
  cursor: pointer;
}

.pane__color-preference input[type="radio"] + label:hover,
.pane__font-preference input[type="radio"] + label:hover {
  transform: scale(1.1);
}

.pane__font-preference,
.pane__color-preference {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 24px 0 24px 0;
  margin: 0 24px 0 24px;
  border-width: 1px 0 1px 0;
  border-style: solid;
  border-color: #e3e1e1;
}

.pane__color-preference {
  border: none;
}

.font-preference__kumbh,
.font-preference__roboto,
.font-preference__space,
.color-preference__default,
.color-preference__blue,
.color-preference__purple {
  width: 40px;
  height: 40px;
  margin: 18px 8px 0 8px;
  border: none;
  border-radius: 90%;
  font-size: 15px;
}

.font-preference__kumbh,
.font-preference__roboto,
.font-preference__space {
  background: #eff1fa;
  background: var(--input-background);
  color: #1e213f;
  color: var(--text-dark);
}

.pane__font-preference input[type="radio"]:checked + label {
  background: #161932;
  background: var(--settings-heading);
  color: #fff;
}

.pane__font-preference input[type="radio"]:hover + label {
  background: #161932;
  background: var(--settings-heading);
  color: #fff;
  opacity: 0.9;
}

.font-preference__kumbh {
  font-family: "Kumbh Sans", sans-serif;
  font-family: var(--font-default);
  font-weight: bold;
  padding-top: 5px;
}

.font-preference__roboto {
  font-family: "Roboto Slab", serif;
  font-family: var(--font-roboto-slab);
  font-weight: normal;
}

.font-preference__space {
  font-family: "Space Mono", monospace;
  font-family: var(--font-space-mono);
  font-weight: bold;
}

.color-preference__default {
  background-color: #f87070;
  background-color: var(--default-accent);
}
.color-preference__blue {
  background-color: #70f3f8;
  background-color: var(--blue-accent);
}
.color-preference__purple {
  background-color: #d881f8;
  background-color: var(--purple-accent);
}

.pane__color-preference input[type="radio"]:checked + label {
  background-image: url("data:image/svg+xml,%3Csvg width='15' height='11' viewBox='0 0 15 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 5.5L4.95263 9.45263L13.4053 1' stroke='%23161932' stroke-width='2'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center;
}

.pane__color-preference input[type="radio"]:hover + label {
  background-image: url("data:image/svg+xml,%3Csvg width='15' height='11' viewBox='0 0 15 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 5.5L4.95263 9.45263L13.4053 1' stroke='%23161932' stroke-width='2'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center;
}

.pane__apply-row {
  width: 100%;
  display: flex;
  justify-content: center;
}

.pane__apply-preferences {
  width: 140px;
  height: 53px;
  margin-top: 36px;
  padding: 0;
  border-radius: 26.5px;
  background-color: #f87070;
  background-color: var(--default-accent);
  font-family: Kumbh Sans;
  font-weight: bold;
  font-size: 16px;
  color: #ffffff;
  transition: transform 0.15s ease-in-out;
  cursor: pointer;
}

.pane__apply-preferences:hover {
  transform: scale(1.1);
}

@media screen and (min-width: 600px) {
  h1 {
    font-size: 32px;
    line-height: 32px;
    margin-top: 48px;
  }

  .pomodoro-app {
    height: 100%;
  }

  .controls {
    width: 373px;
    margin: 55px 0 45px 0;
  }

  .controls__button {
    font-size: 14px;
  }

  .timer {
    width: 410px;
    height: 410px;
  }

  .pane__close-preferences {
    right: 36px;
    top: 39px;
    font-size: 28px;
  }

  .preferences__pane {
    top: 267px;
    width: 540px;
    height: 490px;
    border-radius: 25px;
  }

  .preferences__pane h2 {
    padding: 34px 0 31px 40px;
    font-size: 28px;
    line-height: 28px;
  }

  .time-settings__form {
    margin: 24px 40px 24px 40px;
  }

  .pane__font-preference,
  .pane__color-preference {
    margin: 0 40px 0 40px;
  }

  .pane__time-settings h3 {
    margin: 24px 40px 22px 40px;
  }

  .pane__time-settings h3,
  .pane__font-preference h3,
  .pane__color-preference h3 {
    text-align: left;
    font-size: 13px;
    line-height: 13px;
    letter-spacing: 5px;
  }

  .pane__font-preference h3,
  .pane__color-preference h3 {
    width: 300px;
    margin-top: 18px;
  }

  .font-preference__kumbh,
  .font-preference__roboto,
  .font-preference__space,
  .color-preference__default,
  .color-preference__blue,
  .color-preference__purple {
    margin: 0 8px 0 8px;
  }

  .font-preference__space,
  .color-preference__purple {
    margin: 0 0 0 8px;
  }

  .time-settings__form {
    margin: 18px 40px 24px 40px;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: 12px 48px;
    grid-column-gap: 22px;
    -webkit-column-gap: 22px;
            column-gap: 22px;
  }

  label {
    grid-row: 1;
  }

  input {
    grid-row: 2;
  }
}

@media screen and (min-width: 769px) {
  .preferences__pane {
    top: 155px;
  }
}

